import React, { FC, useCallback, useMemo, useRef, useState } from "react";

import {
  Tooltip,
  CircularProgress,
  Modal,
  Typography,
  Fab,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Snackbar,
  Alert,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Paper,
  Table,
  Chip,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import GroupsIcon from "@mui/icons-material/Groups";
import { Strategy, TeamAction, UserConfig } from "types/user";
import { Box } from "@mui/system";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import {
  faPersonDigging,
  faSackDollar,
} from "@fortawesome/free-solid-svg-icons";

import { capitalizeFirstLetter } from "utils/string";
import { useAsyncAction } from "hooks/async";
import { IntegerInput } from "components/utils/forms";
import {
  DocumentSnapshot,
  FieldPath,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import { useKeyPress } from "hooks/events";

const LootingIcon: FC<Omit<FontAwesomeIconProps, "icon">> = (props) => (
  <FontAwesomeIcon icon={faSackDollar} {...props} />
);
const MiningIcon: FC<Omit<FontAwesomeIconProps, "icon">> = (props) => (
  <FontAwesomeIcon icon={faPersonDigging} {...props} />
);

type TeamSpec = Strategy["teams"][number] & {
  teamId: string;
};

interface TeamActionOption {
  doAction: () => void;
  ActionIcon: React.ElementType;
  title: string;
}

type TeamProps = TeamSpec & {
  actionButtons: TeamActionOption[];
};
const Team: FC<TeamProps> = ({ teamId, actionButtons }) => {
  const [actionMenuAnchorEl, setActionMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const actionMenuOpen = Boolean(actionMenuAnchorEl);
  const handleActionMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setActionMenuAnchorEl(event.currentTarget);
  };
  const handleActionMenuClose = () => {
    setActionMenuAnchorEl(null);
  };
  return (
    <TableRow hover>
      <TableCell>
        <Tooltip title={`Team ${teamId}`}>
          <Chip icon={<GroupsIcon />} label={teamId} variant="outlined" />
        </Tooltip>
      </TableCell>
      <TableCell sx={{ textAlign: "right" }}>
        <Button onClick={handleActionMenuClick}>Actions</Button>
        <Menu
          anchorEl={actionMenuAnchorEl}
          open={actionMenuOpen}
          onClose={handleActionMenuClose}
        >
          {actionButtons.map(({ doAction, ActionIcon, title }, index) => (
            <MenuItem key={index} onClick={doAction}>
              {ActionIcon && (
                <ListItemIcon>
                  <ActionIcon fontSize="small" />
                </ListItemIcon>
              )}
              <ListItemText>{title}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </TableCell>
    </TableRow>
  );
};

interface TeamGroupActionOption {
  doAction: (teamId: string) => void;
  ActionIcon: React.ElementType;
  title: (teamId: string) => string;
}

const TeamActivityGroup: FC<{
  teams: TeamSpec[];
  actionButtons: TeamGroupActionOption[];
}> = ({ teams, actionButtons }) => {
  return (
    <TableContainer component={Paper} variant="outlined">
      <Table>
        <TableBody>
          {teams.map((props) => (
            <Team
              key={props.teamId}
              {...props}
              actionButtons={actionButtons.map(
                ({ doAction, title, ActionIcon }) => ({
                  doAction: () => doAction(props.teamId),
                  title: title(props.teamId),
                  ActionIcon: ActionIcon,
                })
              )}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface TeamModalProps {
  open: boolean;
  onClose: () => void;
  createTeam: (team: TeamSpec) => Promise<void> | void;
  existingTeamIds: string[];
}
const NewTeamModal: FC<TeamModalProps> = ({
  open,
  onClose,
  createTeam,
  existingTeamIds,
}) => {
  const modalRef = useRef<HTMLElement>(null);
  const [teamId, setTeamId] = useState("");
  const [teamAction, setTeamAction] = useState<TeamAction>(TeamAction.MINE);
  const {
    runAction: doCreateTeam,
    running: creatingTeam,
    error,
    clearError,
  } = useAsyncAction(createTeam);

  const validTeamId = teamId && !existingTeamIds.includes(teamId);
  const disabled = creatingTeam || !validTeamId;

  const reset = useCallback(() => {
    setTeamAction(TeamAction.MINE);
    setTeamId("");
  }, [setTeamAction, setTeamId]);

  const doSubmit = useCallback(async () => {
    if (disabled) {
      return;
    }
    const success = await doCreateTeam({
      teamId,
      action: teamAction,
    });
    if (success) {
      reset();
      onClose();
    }
  }, [onClose, reset, doCreateTeam, teamId, teamAction, disabled]);

  const keyHander = useCallback(
    ({ key }: KeyboardEvent) => {
      switch (key) {
        case "Enter":
          doSubmit();
          break;
        case "Escape":
          onClose();
          break;
        default:
          break;
      }
    },
    [doSubmit, onClose]
  );
  useKeyPress(["Enter", "Escape"], keyHander);

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          ref={modalRef}
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="h5" component="h2" textAlign="center">
            New Team
          </Typography>
          <TextField
            label="Team ID"
            variant="standard"
            value={teamId}
            onChange={(e) => setTeamId(e.target.value)}
            error={!validTeamId}
            InputProps={{ inputComponent: IntegerInput as any }}
          />
          <FormControl>
            <FormLabel>Action</FormLabel>
            <RadioGroup
              name="controlled-radio-buttons-group"
              value={teamAction}
              onChange={(e) =>
                setTeamAction(
                  TeamAction[
                    e.target.value as typeof TeamAction[keyof typeof TeamAction]
                  ]
                )
              }
            >
              <FormControlLabel
                value={TeamAction.MINE}
                control={<Radio />}
                label={capitalizeFirstLetter(TeamAction.MINE.toLowerCase())}
              />
              <FormControlLabel
                value={TeamAction.LOOT}
                control={<Radio />}
                label={capitalizeFirstLetter(TeamAction.LOOT.toLowerCase())}
              />
              <FormControlLabel
                value={TeamAction.INACTIVE}
                control={<Radio />}
                label={capitalizeFirstLetter(TeamAction.INACTIVE.toLowerCase())}
              />
            </RadioGroup>
          </FormControl>
          <Box textAlign="center">
            {creatingTeam ? (
              <CircularProgress />
            ) : (
              <Tooltip title="Add team">
                <span>
                  <Fab
                    color="primary"
                    variant="extended"
                    disabled={disabled}
                    onClick={doSubmit}
                  >
                    <AddIcon />
                    Add
                  </Fab>
                </span>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Modal>
      <Snackbar open={!!error} autoHideDuration={5000} onClose={clearError}>
        <Alert onClose={clearError} severity="error" sx={{ width: "100%" }}>
          {`Failed to create team: ${error}`}
        </Alert>
      </Snackbar>
    </>
  );
};

const TeamStrategyTab: FC<{
  userConfigSnapshot: DocumentSnapshot<UserConfig>;
}> = ({ userConfigSnapshot }) => {
  const strategy = userConfigSnapshot?.data()?.strategy;
  const [modalOpen, setModalOpen] = useState(false);
  const existingTeamIds = useMemo(
    () => Object.keys(strategy?.teams || {}),
    [strategy]
  );

  if (!strategy) {
    return <CircularProgress />;
  }
  const lootingTeams: TeamSpec[] = [];
  const miningTeams: TeamSpec[] = [];
  const inactiveTeams: TeamSpec[] = [];
  Object.entries(strategy.teams || {}).forEach((t) => {
    const [teamId, team] = t;
    switch (team.action.valueOf()) {
      case TeamAction.LOOT:
        lootingTeams.push({ teamId, ...team });
        break;
      case TeamAction.MINE:
        miningTeams.push({ teamId, ...team });
        break;
      case "LOOTING":
        lootingTeams.push({ teamId, ...team });
        break;
      case "MINING":
        miningTeams.push({ teamId, ...team });
        break;
      case TeamAction.INACTIVE:
        inactiveTeams.push({ teamId, ...team });
        break;
      default:
        throw new Error(`Invalid team action: ${teamId}, ${team.action}`);
    }
  });

  const changeTeamAction = (teamId: string, action: TeamAction) => {
    updateDoc(
      userConfigSnapshot.ref,
      new FieldPath("strategy", "teams", teamId, "action"),
      action
    );
  };
  const changeToLooting = (teamId: string) => {
    changeTeamAction(teamId, TeamAction.LOOT);
  };
  const changeToMining = (teamId: string) => {
    changeTeamAction(teamId, TeamAction.MINE);
  };

  const deleteTeam = (teamId: string) => {
    updateDoc(
      userConfigSnapshot.ref,
      new FieldPath("strategy", "teams", teamId),
      deleteField()
    );
  };

  const inactivateTeam = (teamId: string) => {
    changeTeamAction(teamId, TeamAction.INACTIVE);
  };

  return (
    <>
      <Box alignItems="center">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            gap: 2,
          }}
        >
          <MiningIcon />
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Mining Teams
          </Typography>
        </Box>
        <TeamActivityGroup
          teams={miningTeams}
          actionButtons={[
            {
              doAction: changeToLooting,
              title: (teamId: string) => `Change team ${teamId} to looting`,
              ActionIcon: LootingIcon,
            },
            {
              doAction: inactivateTeam,
              title: (teamId: string) => `Change team ${teamId} to inactive`,
              ActionIcon: DoDisturbIcon,
            },
            {
              doAction: deleteTeam,
              title: (teamId: string) => `Delete team ${teamId}`,
              ActionIcon: DeleteIcon,
            },
          ]}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            gap: 2,
          }}
        >
          <LootingIcon />
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Looting Teams
          </Typography>
        </Box>
        <TeamActivityGroup
          teams={lootingTeams}
          actionButtons={[
            {
              doAction: changeToMining,
              title: (teamId: string) => `Change team ${teamId} to mining`,
              ActionIcon: MiningIcon,
            },
            {
              doAction: inactivateTeam,
              title: (teamId: string) => `Inactivate team ${teamId}`,
              ActionIcon: DoDisturbIcon,
            },
            {
              doAction: deleteTeam,
              title: (teamId: string) => `Delete team ${teamId}`,
              ActionIcon: DeleteIcon,
            },
          ]}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            gap: 2,
          }}
        >
          <DoDisturbIcon />
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Inactive Teams
          </Typography>
        </Box>
        <TeamActivityGroup
          teams={inactiveTeams}
          actionButtons={[
            {
              doAction: changeToMining,
              title: (teamId: string) => `Change team ${teamId} to mining`,
              ActionIcon: MiningIcon,
            },
            {
              doAction: changeToLooting,
              title: (teamId: string) => `Change team ${teamId} to looting`,
              ActionIcon: LootingIcon,
            },
            {
              doAction: deleteTeam,
              title: (teamId: string) => `Delete team ${teamId}`,
              ActionIcon: DeleteIcon,
            },
          ]}
        />
      </Box>
      <Box textAlign="right" sx={{ marginTop: 2 }}>
        <Tooltip title="Add team">
          <Fab color="primary" onClick={() => setModalOpen(true)}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Box>
      <NewTeamModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        existingTeamIds={existingTeamIds}
        createTeam={(teamProps) => {
          const { teamId, ...team } = teamProps;
          updateDoc(
            userConfigSnapshot.ref,
            new FieldPath("strategy", "teams", teamId),
            team
          );
        }}
      />
    </>
  );
};

export default TeamStrategyTab;
