import React, { FC } from "react";

import { CircularProgress, FormGroup, Typography } from "@mui/material";
import { UserConfig } from "types/user";
import { DocumentSnapshot } from "firebase/firestore";
import { FirestoreBackedSlider } from "components/utils/forms";

const OtherStrategyTab: FC<{
  userConfigSnapshot: DocumentSnapshot<UserConfig>;
}> = ({ userConfigSnapshot }) => {
  const strategy = userConfigSnapshot?.data()?.strategy;
  const updatingAnything = !!userConfigSnapshot?.metadata.fromCache;

  if (!strategy) {
    return <CircularProgress />;
  }

  return (
    <>
      <FormGroup sx={{ p: 2 }}>
        <Typography gutterBottom>Max gas (gwei)</Typography>
        <FirestoreBackedSlider
          disabled={updatingAnything}
          docSnap={userConfigSnapshot!}
          min={0}
          max={150}
          fieldPath="strategy.maxGas"
          valueLabelDisplay="auto"
          marks={[
            {
              value: 0,
              label: "0",
            },
            {
              value: 150,
              label: "150",
            },
          ]}
          sx={{ maxWidth: 300 }}
        />
      </FormGroup>
    </>
  );
};

export default OtherStrategyTab;
