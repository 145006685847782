import NotificationsTab from "./NotificationsTab";
// import OtherPreferencesTab from "./OtherPreferences";

const preferencesTabsList = [
  {
    key: "notifications",
    label: "Notifications",
    component: NotificationsTab,
  },
  // { key: "other", label: "Other", component: OtherPreferencesTab },
];

export default preferencesTabsList;
