import OtherStrategyTab from "./OtherStrategyTab";
import ReinforcementsStrategyTab from "./ReinforcementsStrategyTab";
import TeamStrategyTab from "./TeamStrategyTab";

const strategyTabsList = [
  {
    key: "teams",
    label: "Teams",
    component: TeamStrategyTab,
  },
  {
    key: "reinforcements",
    label: "Reinforcements",
    component: ReinforcementsStrategyTab,
  },
  {
    key: "other",
    label: "Other",
    component: OtherStrategyTab,
  },
];

export default strategyTabsList;
