export enum TeamAction {
  MINE = "MINE",
  LOOT = "LOOT",
  INACTIVE = "INACTIVE",
}

export interface Strategy {
  teams: {
    [id: string]: {
      // The current action for the team to perform
      action: TeamAction;
    };
  };
  reinforcingCrabs: {
    [id: string]: {
      action: TeamAction;
    };
  };
  reinforceEnabled: boolean;
  maxReinforcement: number;
  maxGas: number;
}

export interface Preferences {
  notifications: {
    sms: {
      phoneNumber: string;
      lootUpdatesEnabled: boolean;
      alertUpdatesEnabled: boolean;
    };
    email: {
      email: string;
      updatesEnabled: boolean;
    };
  };
}

export interface UserConfig {
  strategy: Strategy;
  preferences: Preferences;
}
