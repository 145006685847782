import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC5iHY4S-mtKQ4zPp1-dEOUbFJyUWaYr3A",
  authDomain: "nft-web-server.firebaseapp.com",
  databaseURL: "https://nft-web-server-default-rtdb.firebaseio.com",
  projectId: "nft-web-server",
  storageBucket: "nft-web-server.appspot.com",
  messagingSenderId: "107046469668",
  appId: "1:107046469668:web:7b8cc9fb5cffed5bc558b1",
  measurementId: "G-KGMJM0GL38",
};

const myApp = initializeApp(firebaseConfig);
export default myApp;
